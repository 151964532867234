<template>
<div class="welcome py-5">
    <!-- <div class="image-wrapper"> -->
    <v-img :class="[$vuetify.breakpoint.mdAndUp ? 'Logo ma-4' : 'Logo-mobile ma-3 mr-0']" src="@/assets/glf-logo-full.png" contain></v-img>
    <!-- </div> -->
    <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'welcome-heading pa-6' : 'welcome-heading-mobile pa-3']">
        Welcome To Mastering Emotional Health
    </h1>
    <div class="d-flex welcome-icons">
        <i class="neu-book-open welcome-icon"></i>
        <i class="neu-tea-leaf welcome-icon"></i>
        <i class="neu-boat welcome-icon"></i>
    </div>
    <p :class="[$vuetify.breakpoint.mdAndUp ? 'welcome-intro pa-6' : 'welcome-intro-mobile pa-3']">
        This journey consists of 21 ways to Master Emotional Health and enhance your wellbeing. Each of the 21 sessions
        includes an introduction and illustration, plus a meditation and recreation that, if you practise what you learn,
        will enhance your progress towards Mastering Emotional Health.
    </p>

    <v-btn :class="[$vuetify.breakpoint.mdAndUp ? 'pa-7 mt-4' : ' pa-6']" dark @click="goTo()"><span class="welcome-button-text">Continue To Sessions</span></v-btn>

    <!-- <mini-audio-player /> -->
</div>
</template>

<script>
import MiniAudioPlayer from "@/components/misc/meo-mini-audio-player";

export default {
    components: {
        MiniAudioPlayer,
    },

    methods: {
        goTo() {
            this.$router.push({
                name: "modules",
            });
        },
    },
};
</script>

<style lang="scss">
/* .image-wrapper{
    align-self: center;
} */
.welcome-icon {
    font-size: 60px !important;
    padding: 12px;
}

.welcome-button-text {
    font-size: 18px;
    font-weight: bold;
}

.Logo {
    max-height: 40px;
    max-width: 300px;
    position: absolute;
    top: 0;
    right: 0;
}
.Logo-mobile {
    max-height: 40px;
    max-width: 300px;
    position: absolute;
    top: 0;
    right: 0;
}

.welcome-heading {
    font-size: 58px;
    text-align: center;
    margin-top: 1rem;
    color: #585858;
}
.welcome-heading-mobile {
    font-size: 30px;
    text-align: center;
    margin-top: 2rem;
    color: #585858;
}
.welcome-icons {
    color: #585858;
}

.welcome-intro {
    font-size: 18px;
    font-weight: 400;
    max-width: 50vw;
    text-align: center;
    line-height: 2.7;
}
.welcome-intro-mobile {
    max-width: 90%;
    font-size: 16px;
    font-weight: 530;
    text-align: center;
    line-height: 2;
}

.welcome {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: #fff752;
    min-height: 100%;
    width: 100%;
}

/* .welcome-mobile{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: #FFF200;
    min-height: 100%;
    width: 100%;
} */
</style>
