<template>
  <div>
    <v-card class="mini-audio-player pa-1">
      <div class="d-flex flex-no-wrap justify-space-around">
        <v-card-actions>
          <v-btn v-if="!playing" icon text class="mx-3" @click="playAudio()">
            <div class="button-background padding-16px">
              <v-icon size="48" color="var(--v-heading-base)">mdi-play</v-icon>
            </div>
          </v-btn>
          <v-btn v-else icon text class="mx-3" @click="playAudio()">
            <div class="button-background pa-4 ">
              <svg class="wave" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 38.05">
                <path
                  id="Line_1"
                  data-name="Line 1"
                  d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
                />
                <path
                  id="Line_2"
                  data-name="Line 2"
                  d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"
                />
                <path
                  id="Line_3"
                  data-name="Line 3"
                  d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"
                />
                <path
                  id="Line_4"
                  data-name="Line 4"
                  d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"
                />
                <path
                  id="Line_5"
                  data-name="Line 5"
                  d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"
                />
                <path
                  id="Line_6"
                  data-name="Line 6"
                  d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"
                />
                <path
                  id="Line_7"
                  data-name="Line 7"
                  d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"
                />
                <path
                  id="Line_8"
                  data-name="Line 8"
                  d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"
                />
                <path
                  id="Line_9"
                  data-name="Line 9"
                  d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"
                />
              </svg>
            </div>
          </v-btn>
        </v-card-actions>
        <div class="d-flex flex-column justify-center align-center">
          <v-card-title class="text-h6 py-3">Welcome Audio</v-card-title>
          <v-card-subtitle>By Gayle</v-card-subtitle>
        </div>
      </div>
    </v-card>
    <audio ref="audio" id="audio" preload="metadata"></audio>
  </div>
</template>

<script>
export default {
  name: "meo-meditation-player",
  props: {
    audio: {
      type: Object,
    },
  },
  data() {
    return {
      audioProgress: 0,
      audioDuration: 0,
      currentTimeText: "0:00",
      totalTimeText: "0:00",
      playing: false,
      favourite: false,
    };
  },
  computed: {
    audioTitle() {
      return (this.audio && this.audio.title) || "";
    },
  },
  methods: {
    playAudio() {
      // if audio isnt playing then play it and change the playing data value to true
      // if it is playing then pause it and set the playing data value to false
      if (!this.playing) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }

      this.playing = !this.playing;
    },
    resetAudio() {
      this.playing = false;
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
    },
    setDuration() {
      // when audio readyState has reached greater than 2 (loaded audio metadata), set audioDuration
      if (this.$refs.audio.readyState >= 2) {
        this.totalTimeText = this.millisToMinutesAndSeconds(this.$refs.audio.duration * 1000);
        this.audioDuration = this.$refs.audio.duration;
      }
    },
    millisToMinutesAndSeconds(millis) {
      // takes in a time in milliseconds and outputs a string showing minute & seconds format (0:00 ect.)
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return seconds == 60 ? minutes + 1 + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    },
    async loadAudio() {
      // get audio source from the backend
      // Need to add "from" so that Fluro knows to get it from the purchased product
      let audio = this.section?.data?.audio || this.audio;

      let url = this.$fluro.asset.getUrl(audio, {
        from: this.$route.params.id,
      });

      this.$nextTick(function() {
        // assign the audio source to the audio html element
        this.$refs.audio.src = url;
        // run the load method on the audio element to prepare audio file
        this.$refs.audio.load();
        // add listener to check if data has loaded if so set and format the duration time
        // added due to duration setting as NaN as the data had not loaded
        this.$refs.audio.addEventListener("loadeddata", this.setDuration);
      });

      //   await this.playTimeControl();
      await this.playAudio();
    },
  },

  async mounted() {},
};
</script>

<style lang="scss">
.mini-audio-player {
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 0px !important;
}

.padding-16px {
  padding: 16px !important;
}

// .v-icon::after {
//   background-color: rgba(0, 0, 0, 0) !important;
// }
// .icon-size {
//   font-size: 36px !important;
// }
// .favourite-size {
//   font-size: 24px;
// }

// .icon-button {
//   background-color: rgba(0, 0, 0, 0) !important;
// }
// .headingColor {
//   color: var(--v-heading-base);
// }
.button-background {
  background-color: #fff752;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid grey;
}

.wave {
  height: 30px;
  width: 30px;
  fill: black;
}

@for $i from 1 through 9 {
  #Line_#{$i} {
    animation: pulse 1s infinite;
    animation-delay: $i * 0.15s;
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}
</style>
